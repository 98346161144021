.Modal{
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 30%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}
@media(min-width: 600px){
    .Modal{
        width: 500px;
        left: calc(50% -250px);
    }
}

.modal-cross{
    position: absolute;
    top: 0px;
    right: 10px;
    cursor: pointer;
}
.arrow-right{
    cursor: pointer;
}

.popover-container {
    position: relative;
    display: inline-block;
  }
  
  .popover-trigger {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .popover-content {
    position: absolute;
    top: 40px;
    left: 0;
    width: 250px;
    padding: 10px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .popover-arrow {
    position: absolute;
    top: -10px;
    left: 10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ddd;
  }
  
  .popover-header {
    display: flex;
    align-items: center;
    padding: 0;
  }
  
  .popover-input {
    width: calc(100% - 35px);
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-right: 5px;
  }
  
  .popover-send {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .popover-suggestions {
    border-top: 1px solid #ddd;
    margin-top: 10px;
  }
  
  .popover-suggestion {
    padding: 8px;
    cursor: pointer;
  }
  
  .popover-suggestion:hover {
    background-color: #f1f1f1;
  }

  /* Container that holds Typeahead and Button */
.typeahead-container {
    position: relative;
    display: flex;
    width: 100%; /* Ensure the container takes full width */
  }
  
  /* Styling for the Typeahead input field */
  .search-domains {
    width: 100%; /* Make the input field take full width of the container */
    padding-right: 40px; /* Space for the button inside the input */
    box-sizing: border-box; /* Ensure padding is included in the width calculation */
  }
  
  /* Styling for the button inside the input field */
  .popover-send {
    position: absolute;
    right: 10px; /* Adjust the position of the button */
    top: 50%;
    transform: translateY(-50%); /* Center the button vertically */
    background-color: transparent;
    border: none;
    font-size: 18px; /* You can adjust this to your preferred size */
    cursor: pointer;
    color: #007bff; /* Adjust color as needed */
    z-index: 10; /* Ensure the button stays on top */
  }
.rbt-menu{
    width:228px !important;
}  