.custom-spinner{
    position: fixed;
    top: 50px;
    left: 50%;
    z-index: 100;
}
.custome-spinner{
    position: fixed;
    top: 50px;
    left: 50%;
    z-index: 100;
}

.custom-spinner-hide{
    display: none !important;
}